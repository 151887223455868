@import "src/assets/scss/variables";

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f3f5fa;
  padding-right: 15px;
  padding-left: 15px;
  &__container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    // padding-right: 15px;
  }
  &__wrap {
    display: flex;
    flex-grow: 1;
    margin-top: 40px;
  }
  &__sidebar {
    margin-top: 0px;
    width: 88px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    //margin-right: 30px;
    margin-right: 50px;

    &-profile {
      height: 77px;
      display: flex;
      //justify-content: space-between;
      align-items: center;
      flex-direction: column;
      background: #FFFFFF;
      box-sizing: border-box;
      border-radius: 10px;
      margin-top: 2px;
      &_show {
        cursor: pointer;
      }
      &_container {
        display: flex;
        padding: 10px 0;
      }
      &_photo {
        margin-right: 9px;
        border-radius: 10px;
      }
      &_name {
        font-size: 13px;
        font-family: Inter, sans-serif;
        font-weight: 600;
      }
      &_logout {
        display: block;
        width: 100%;
        text-align: center;
        border-top: 1px solid #EEEFF6;
        font-family: Inter, sans-serif;
        font-size: 11px;
        color: $color_blue;
        font-weight: 600;
        padding-top: 8px;
        img {
          width: 10px;
          margin-left: 6px;
        }
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      &_desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &-counter {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-family: Inter, sans-serif;
      font-size: 11px;
      width: 20px;
      height: 20px;
      border-radius: 7px;
      background: #FF5C5C;
      box-sizing: border-box;
      font-weight: 600 !important;
      margin-left: 15px;
      position: absolute;
      right: 5px;
      top: 5px;
    }

    &-nav {
      background: #FFFFFF;
      border-radius: 10px;
      &-icon {
        margin-bottom: 8px;
      }
      &-link {
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        border-left: 4px solid transparent;
        text-decoration: none;
        border-bottom: 1px solid #EEEFF6;
        font-family: Inter, sans-serif;
        font-size: 9px;
        font-weight: 500;
        position: relative;
        text-transform: uppercase;
        color: #919AB3;
        &_active,
        &:hover {
          text-decoration: none;
          color: #2361FF;
          svg {
            path {
              fill: #2361FF;
            }
          }
        }
      }
      li {
        display: flex;
        align-items: center;
        justify-content: space-between
      }
    }
  }

  &__support {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #EEF0F4;
    padding: 20px;
    border-radius: 10px;
    margin: 10px 30px;
    &-heading {
      font-family: Inter;
      font-weight: 700;
      font-size: 16px;
    }
    &-description {
      font-size: 13px;
      color: $color_grey_light;
      padding-top: 6px;
    }
    &-link {
      color: $color_blue;
      font-weight: 600;
    }
    &-image {
      max-width: 100px;
      align-self: center;
      margin-top: 25px;
    }
  }
}

.sidebar {
  &__faq {
    width: 230px;
    height: 202px;
    background: #2F7DD9;
    border-radius: 20px;
    position: relative;
    margin: 50px auto;
    &-heading {
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      color: #FFFFFF;
      margin-left: 21px;
      padding-top: 11px;
    }
    &-person {
      height: 140px;
      position: absolute;
      left: -14px;
      bottom: 0;
    }
    &-link {
      position: absolute;
      right: 20px;
      bottom: 20px;
      display: block;
      cursor: pointer;
    }
    &-text {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #FFFFFF;
      display: block;
    }
    &-link_img {
      position: absolute;
      right: 0;
      bottom: -2px;
    }
  }
}


.marquee_block {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marquee_block p {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  color: #000000;
  /*animation: marquee 50s linear infinite;*/
}

@media only screen and (max-width: 500px) {
  .layout {
    &__wrap {
      margin-top: 0;
    }
  }
  .marquee_block {
    height: inherit;
    p {
      padding: 5px 0;
      margin: 0 5px;
      text-align: center;
    }
  }
}

/*
.marquee p:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}*/
